import React from "react"
import { css } from "styled-components"

import Layout from "../components/Layout"
import Container from "../components/Container"
import MenuButton from "../components/MenuButton"

import UniSopron from "../svg/uni-sopron.svg"

import Egyetemunk from "../svg/menu/egyetemunk.svg"
import Erettsegi from "../svg/menu/erettsegi.svg"
import HolVagyok from "../svg/menu/hol_vagyok.svg"
// import Jatek from "../svg/menu/jatek.svg"
import MiLegyek from "../svg/menu/mi_legyek.svg"
import Orarend from "../svg/menu/orarend.svg"
import Sopron from "../svg/menu/sopron.svg"
import Szakleiras from "../svg/menu/szakleiras.svg"
import Tata from "../svg/menu/tata.svg"
// import Tananyag from "../svg/menu/tananyag.svg"

const IndexPage = () => (
  <Layout>
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <img src={UniSopron} alt="unisopron" />
      {/* <p
        css={css`
          text-transform: uppercase;
          font-size: 40px;
          color: white;
          font-family: "Nunito", sans-serif;
          text-align: center;
        `}
      >
        #uniSopron
      </p> */}
      <p
        css={css`
          text-transform: uppercase;
          margin: 14px 0;
          font-size: 29px;
          text-shadow: 0px 3px 6px #00000029;
          color: white;
          font-family: "Nunito", sans-serif;
          text-align: center;
        `}
      >
        Soproni Egyetem
      </p>
    </div>
    <Container
      css={css`
        padding: 30px;
        padding-bottom: calc(120px + env(safe-area-inset-bottom));
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
          grid-gap: 30px;
        `}
      >
        <MenuButton to="/mi-legyek" image={MiLegyek} title="Mi legyek?" />
        <MenuButton to="/hol-vagyok" image={HolVagyok} title="Hol vagyok?" />
        {/* <MenuButton disabled image={Tananyag} title="Tananyagok" /> */}
        <MenuButton to="/orarend" image={Orarend} title="Órarend" />
        <MenuButton to="/szakleiras" image={Szakleiras} title="Szakleírás" />
        {/* <MenuButton disabled image={Jatek} title="Játék" /> */}
        <MenuButton disabled image={Sopron} title="Sopron" />
        <MenuButton
          to="/erettsegi"
          image={Erettsegi}
          title="Érettségi felkészítés"
        />
        <MenuButton to="/egyetemunk" image={Egyetemunk} title="Egyetemünk" />
        <MenuButton to="/epuletek" image={Egyetemunk} title="Kereső" />
        <MenuButton to="/tata" image={Tata} title="Tata" />
      </div>
    </Container>
  </Layout>
)

export default IndexPage
