import React from "react"
import { css } from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import CircleImage from "./CircleImage"

const MenuButton = ({ image, title, disabled, to = "/" }) => (
  <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
    <Link
      to={to}
      css={css`
        text-align: center;
        opacity: ${disabled ? 0.3 : 1};
        text-decoration: none;
        outline: 0;
        display: flex;
        flex-direction: column;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-touch-callout: none;
      `}
    >
      <CircleImage image={image} />
      <p
        css={css`
          color: #707070;
          font-size: 14px;
        `}
      >
        {title}
      </p>
    </Link>
  </motion.div>
)

export default MenuButton
